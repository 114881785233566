<i18n lang="json">
    {
      "ru": {
        "title": "Бронировать в приложении Суточно.ру — ещё удобнее и&nbsp;быстрее!",
        "subtitle": "Все варианты, брони и переписка с хозяевами у вас в кармане.",
        "camera": "Наведите камеру на QR-код, чтобы скачать приложение",
      },
      "en": {
        "title": "Booking in the Sutochno.ru application is even more convenient and faster!",
        "subtitle": "All options, reservations and correspondence with the owners are in your pocket.",
        "camera": "Point your camera at the QR code to download the app",
      }
    }
</i18n>

<template>
  <div class="bookin-app">
    <div class="bookin-app__textes">
      <p class="bookin-app__title heading-h6" v-html="t('title')"></p>
      <p class="bookin-app__subtitle regular-p5">{{ t("subtitle") }}</p>
      <a
        :href="
          isIOS
            ? 'https://redirect.appmetrica.yandex.com/serve/892891521215365358'
            : 'https://redirect.appmetrica.yandex.com/serve/820833924912576402'
        "
        :key="appButtonKey"
        target="_blank"
        class="bookin-app__button"
      >
        <div class="bookin-app__market" v-if="isIOS">
          <span class="bookin-app__icon bookin-app__icon_ios"></span>

          <span class="bookin-app__available bookin-app__available_ios"></span>

          <img class="bookin-app__right" src="~/assets/img/arrow-black.svg"/>
        </div>
        <div class="bookin-app__market" v-else>
          <span class="bookin-app__icon bookin-app__icon_android"></span>

          <span class="bookin-app__available bookin-app__available_android"></span>

          <img class="bookin-app__right" src="~/assets/img/arrow-black.svg"/>
        </div>
      </a>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const { t } = useI18n();

const store = useStore();
const isIOS = computed(() => store.state.user.isIOS || false);

const appButtonKey = ref(0);

const updateButtonKey = () => {
  appButtonKey.value += 1;
};

onMounted(() => {
  updateButtonKey();
});

</script>

<style lang="scss" scoped>
.bookin-app {
  margin-top: 54px;
  background-color: var(--color-gray-g3);
  border-radius: 20px;
  padding: 16px;
  background-image: url("@/assets/img/main/bookinapp-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  gap: 37px;

  &__button {
    position: relative;
    display: flex;
    background-color: var(--color-white);
    box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.02),
      0px 5px 10px rgba(20, 45, 136, 0.02);
    border-radius: 8px;
    padding: 10px 17px;
    align-items: center;
  }

  &__market {
    display: flex;
    column-gap: 6px;
  }

  &__icon {
    display: block;
    width: 25px;
    height: 27px;
    background-position: center center;
    background-size: cover;

    &_android {
      background-image: url("@/assets/img/icon/google-play-new.svg");
    }
    &_ios {
      background-image: url("@/assets/img/icon/app-store-new.svg");
    }
  }

  &__available {
    display: block;
    width: 86px;
    height: 26px;
    background-position: center center;
    background-size: cover;

    &_android {
      background-image: url("@/assets/img/icon/available-google-play.svg");
    }
    &_ios {
      width: 67px;
      background-image: url("@/assets/img/icon/available-app-store.svg");
    }
  }

  &__right {
    transform: rotate(180deg);
    position: absolute;
    top: calc(50% - 8px);
    right: 16px;
  }

  &__textes {
    width: 100%;
  }

  &__title {
    color: var(--color-white);
    margin-bottom: 8px;
    max-width: 260px;
  }

  &__subtitle {
    color: var(--color-white);
    margin-bottom: 20px;
    max-width: 240px;
  }

  &__apps {
    display: flex;
    flex-direction: column;
    min-width: 152px;
    max-width: 152px;
    justify-content: flex-end;
    margin-right: -10px;

    a {
      margin-bottom: -15px;
    }

    img {
      width: 100%;
    }
  }

  &__images {
    display: flex;
    gap: 26px;
  }

  &__qr {
    display: flex;
    align-items: flex-end;
  }

  &__code-img {
    background: var(--color-white);
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.02),
      0px 5px 10px rgba(20, 45, 136, 0.02);
    min-width: 91px;
    max-width: 91px;
    height: 90px;

    img {
      width: 100%;
    }
  }

  &__qr-text {
    color: var(--color-gray-g5);
    background: var(--color-gray-g2);
    border-radius: 18px 0px 18px 20px;
    padding: 10px 15px;
    margin-right: 6px;
  }
}
</style>
