const images = import.meta.glob([
  // "assets/img/main/routeDirections/ancient-cities.jpg",
  // "assets/img/main/routeDirections/ancient-cities.webp",
  // "assets/img/main/routeDirections/istanbul-holidays.jpg",
  // "assets/img/main/routeDirections/istanbul-holidays.webp",
  // "assets/img/main/routeDirections/palaces-castles.jpg",
  // "assets/img/main/routeDirections/palaces-castles.webp",
  // "assets/img/main/routeDirections/what-to-see-spb.jpg",
  // "assets/img/main/routeDirections/what-to-see-spb.webp",
  "assets/img/main/routeDirections/ski-resorts.jpg",
  "assets/img/main/routeDirections/ski-resorts.webp",
  "assets/img/main/routeDirections/na-more-zimoy.jpg",
  "assets/img/main/routeDirections/na-more-zimoy.webp",
  "assets/img/main/routeDirections/baikal-car.jpg",
  "assets/img/main/routeDirections/baikal-car.webp",
  "assets/img/main/routeDirections/unusual-museums.jpg",
  "assets/img/main/routeDirections/unusual-museums.webp"
], { eager: true, query: "?url", import: "default" });

const directionCards = [
  {
    // Горнолыжные курорты России
    link: "https://sutochno.ru/info/gornolyzhnye-kurorty-russia",
    label: "ideaLabel4",
    description: "ideaDescription4",
    img: images["/assets/img/main/routeDirections/ski-resorts.jpg"],
    imgWebp: images["/assets/img/main/routeDirections/ski-resorts.webp"],
  },
  {
    // На море зимой
    link: "https://sutochno.ru/info/gde-otdokhnut-na-more-zimoy",
    label: "ideaLabel3",
    description: "ideaDescription3",
    img: images["/assets/img/main/routeDirections/na-more-zimoy.jpg"],
    imgWebp: images["/assets/img/main/routeDirections/na-more-zimoy.webp"],
  },
  {
    // На Байкал зимой на машине
    link: "https://sutochno.ru/info/edem-na-bajkal-zimoy-samostoyatelno?from=mainpage",
    label: "ideaLabel",
    description: "ideaDescription",
    img: images["/assets/img/main/routeDirections/baikal-car.jpg"],
    imgWebp: images["/assets/img/main/routeDirections/baikal-car.webp"],
  },
  {
    // Необычные музеи
    link: "https://sutochno.ru/info/neobychnye-muzei-rossii?from=mainpage",
    label: "ideaLabel2",
    description: "ideaDescription2",
    img: images["/assets/img/main/routeDirections/unusual-museums.jpg"],
    imgWebp: images["/assets/img/main/routeDirections/unusual-museums.webp"],
  },
  {
    link: "https://t.me/sutochnoru",
    label: "ideaLabel5",
    description: "ideaDescription5",
  },
];

export default directionCards;