<i18n lang="json">
{
  "ru": {
    "title": "Максимальный кэшбэк \n в приложении Суточно.ру!",
    "desc": "Скачайте наше приложение и бронируйте \n с повышенным кэшбэком",
    "download": "Загрузите",
    "openIn": "Откройте в",
    "availableIn": "Доступно в",
    "qr": "или наведите камеру телефона на этот QR-код"
  },
  "en": {
    "title": "Maximum cashback \n in the Sutochno.ru application!",
    "desc": "Download our app and book \n with increased cashback",
    "download": "Download",
    "openIn": "Open in",
    "availableIn": "Available in",
    "qr": "or point your phone camera at this QR code"
  }
}
</i18n>

<template>
  <SimpleModal :modelValue="modelValue" @update:modelValue="emits('update:modelValue', $event)">
    <section class="app-info">
      <div class="app-info__container">
        <div class="app-info__header">
          <img class="app-info__logo" src="~/assets/img/appInfo/logo.png" alt="">
          <h2 class="app-info__title heading-h5">
            {{ t("title") }}
          </h2>
          <p class="app-info__desc regular-p5">
            {{ t("desc") }}
          </p>
        </div>
        <div class="app-info__links">
          <a
            v-for="application in applicationsList"
            :href="`https://redirect.appmetrica.yandex.com/serve/${application.metricaId}`"
            :aria-label="application.ariaLabel"
            target="_blank"
            rel="noopener noreferrer"
            class="no-link app-info__link"
            @click="handleApplicationClick(application.id)"
          >
            <img :src="application.icon"/>
            <div class="app-info__text">
              <span class="app-info__text-prefix">{{ t(application.textPrefix) }}</span>
              <img class="apple-text" :src="application.textIcon"/>
            </div>
          </a>
        </div>
        <div class="app-info__qr">
          <p class="app-info__qr-desc regular-p7 ">{{ t("qr") }}</p>
          <img src="~/assets/img/appInfo/qr.png" alt="">
        </div>
      </div>
    </section>
  </SimpleModal>
</template>

<script setup>
import { SimpleModal } from "@sutochno/modal";
import { useI18n } from 'vue-i18n';
import GalleryIcon from "~/assets/img/appInfo/app-gallery-icon.png";
import GalleryTextIcon from "~/assets/img/appInfo/app-gallery-text.svg";
import GoogleIcon from "~/assets/img/appInfo/google.png";
import GoogleTextIcon from "~/assets/img/appInfo/google-text.svg";
import AppleIcon from "~/assets/img/appInfo/apple.png";
import AppleTextIcon from "~/assets/img/appInfo/apple-text.svg";

const { t } = useI18n();

const props = defineProps({
  modelValue: Boolean
});
const emits = defineEmits(["update:modelValue"]);

const applicationsList = [
  {
    id: "AppleStore",
    textPrefix: "download",
    textIcon: AppleTextIcon,
    icon: AppleIcon,
    ariaLabel: "Apple Store Sutochno application",
    metricaId: "461099388622672100",
  },
  {
    id: "AppGallery",
    textPrefix: "availableIn",
    textIcon: GalleryTextIcon,
    icon: GalleryIcon,
    ariaLabel: "App Gallery Sutochno application",
    metricaId: "893444965114333231",
  },
  {
    id: "GooglePlay",
    textPrefix: "openIn",
    textIcon: GoogleTextIcon,
    icon: GoogleIcon,
    ariaLabel: "Google Play Sutochno application",
    metricaId: "893444963872828178",
  }
];

watch(() => props.modelValue, () => {
  const eventAction = props.modelValue ? "ModalDownloadApp_Show" : "ModalDownloadApp_ClickInSite";
  matomoHelper({
    eventCategory: "ModalDownloadApp",
    eventAction,
  });
});

function handleApplicationClick(id) {
  matomoHelper({
    eventCategory: "ModalDownloadApp",
    eventAction: "ModalDownloadApp_ClickDownload",
    eventName: id,
  });
}

</script>

<style lang="scss" scoped>
.app-info{
  max-width: 482px;
  width: 100%;
  padding: 30px 0;
  
  &__container{
    max-height: calc(100vh - 80px - 52px);
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    overflow-y: auto;
  }
  &__header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: center;
    white-space: pre-line;
  }
  &__title, &__desc{
    margin: 0;
  }
  &__links{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  &__link{
    width: 134px;
    height: 44px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    background: rgba(28, 28, 28, 1);
    border-radius: 6px;
    .icon{
      color: var(--color-white);
    }
  
    &.image{
      padding: 0;
    }
  
    &:hover{
      opacity: 1;
      background: rgba(28, 28, 28, 0.9)
    }
    &:active{
      background: rgba(28, 28, 28, 0.9);
    }
  
    .icon-app-apple-store{
      font-size: 24px;
    }
  }
  &__text{
    min-height: 24px;
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    justify-content: space-between;
    color: var(--color-white);
  
    &-prefix{
      font-size: 8px;
      line-height: 8px;
    }
  
    .icon-app-apple-store-text{
      font-size: 14px;
    }
    .icon-app-google-play-text{
      font-size: 15px;
    }
  }

  &__qr{
    text-align: center;
  }
  &__qr-desc{
    margin-bottom: 10px;
    color: rgba(90, 93, 99, 1);
  }
}
</style>