<i18n lang="json">
  {
    "ru": {
      "routDirectionTitle": "Идеи для отпуска",

      "ideaLabel": "Маршруты",
      "ideaLabel2": "Что посмотреть",
      "ideaLabel3": "Куда поехать",
      "ideaLabel4": "Экстрим",
      "ideaLabel5": "Telegram",

      "ideaDescription": "На Байкал зимой на машине",
      "ideaDescription2": "Необычные музеи",
      "ideaDescription3": "На море зимой",
      "ideaDescription4": "Горнолыжные курорты России",
      "ideaDescription5": "Больше интересного в Телеграм-канале",

      "title": "Идеи для отпуска",
      "blog": "Перейти в блог"
    },
    "en": {
      "routDirectionTitle": "Vacation Ideas",

      "ideaLabel": "Routes",
      "ideaLabel2": "What to See",
      "ideaLabel3": "Where to go",
      "ideaLabel4": "Extreme",
      "ideaLabel5": "Telegram",

      "ideaDescription": "To Baikal in winter by car",
      "ideaDescription2": "Unusual museums",
      "ideaDescription3": "At sea in winter",
      "ideaDescription4": "Ski resorts in Russia",
      "ideaDescription5": "More interesting things in the Telegram channel",

      "title": "Travel Ideas",
      "blog": "Go to blog"
    }
  }
</i18n>

<template>
  <div class="route-direction">
    <h2 class="route-direction__title">
      {{ t("title") }}
    </h2>
    <swiper :slides-per-view="'auto'" :space-between="14" class="route-direction__slider">
      <swiper-slide v-for="(card, i) in directionCards" :key="i">
        <div
          class="route-direction__card"
          :class="{ 'route-direction__card--empty': !card?.img }"
        >
          <a :href="card.link" :target="card?.img ? '_self' : '_blank'">
            <span
              class="route-direction__card-label card-label"
              :class="{
                'card-label--blue': !card?.img,
                [!card?.img ? 'medium-p6' : 'semibold-p6']: true,
              }"
            >
              {{ t(`${card.label}`) }}
            </span>

            <picture v-if="card?.img">
              <source
                v-if="card.imgWebp"
                :srcset="card.imgWebp"
                type="image/webp"
              />
              <img
                :src="card.img"
                loading="lazy"
                :alt="t(`${card.description}`)"
              />
            </picture>
          </a>
        </div>
        <span class="route-direction__card-title heading-h6" v-html="t(card.description)"></span>
      </swiper-slide>
    </swiper>

    <a href="https://sutochno.ru/info?from=mainpage" class="route-direction__blog">
      {{ t("blog") }}
    </a>
  </div>
</template>

<script>
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useI18n } from 'vue-i18n';
import directionCards from "@/composables/static/routeDirection";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    directionCards() {
      return directionCards;
    },
    getCntSlide(){
      return window.innerWidth / 146;
    }
  },

};
</script>

<style lang="scss" scoped>
.route-direction {
  width: 100%;
  :deep() {
    .swiper-slide {
      width: 130px;

      &:first-child{
        margin-left: 20px;
      }
      &:last-child{
        margin-right: 20px;
      }
    }
  }

  &__title {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: var(--color-main-black);
  }

  &__card {
    position: relative;
    width: 130px;
    height: 154px;
    border-radius: 16px;
    overflow: hidden;


    .card-label {
      left: 8px;
      padding: 3px 7px;
      position: absolute;
      top: 8px;
      z-index: 2;
      font-size: 11px;
      line-height: 12px;
    }

    a {
      position: relative;
      display: block;
      height: 100%;
      border-radius: 16px;
      overflow: hidden;

      &:hover {
        img {
          transform: scale(1.1);
        }
      }

      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 48.28%,
          rgba(0, 0, 0, 0.3) 100%
        );
        z-index: 1;
      }
    }

    &--empty {
      padding-right: 16px;
      box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.02),
        0px 5px 10px rgba(20, 45, 136, 0.02);

      a {
        background: var(--color-white);
        border-radius: 18px;
        z-index: 2;

        &:before {
          content: none;
        }
      }

      &:before,
      &:after {
        content: "";
        width: 91px;
        height: 100%;
        border-radius: 18px;
        position: absolute;
        top: 0;
      }

      &:before {
        background: #edf0f9;
        right: 8px;
        box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.02),
          0px 5px 10px rgba(20, 45, 136, 0.02);
        z-index: 1;
      }

      &:after {
        background: #e2e6f0;
        right: 0;
        box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.08),
          0px 4px 15px rgba(20, 45, 136, 0.06);
        z-index: 0;
      }

      .route-direction__card-title {
        color: var(--color-main-black);
        text-shadow: none;
        font-size: 17px;
        padding-right: 25px;
      }
    }
  }

  &__card-title {
    display: inline-block;
    margin-top: 10px;
    width: 130px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.2px;
    color: var(--color-main-black);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
  }

  &__blog{
    margin: 16px 20px 0;
    // width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-gray-g3);
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-main-black)
  }
}
</style>
