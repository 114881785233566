<template>
  <div v-if="activeLink" class="get-miles">
    <a 
      class="get-miles__text" 
      target="_blank" 
      :href="activeLink.href"
    >
      {{ activeLink.text }}
    </a>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const whitelabelName = computed(() => store.state.user.whitelabel?.name || "");

const wlConfig = {
  utair: {
    href: "https://media.utair.ru/status",
    text: "Utair Status: получайте до 13 % милями от стоимости брони"
  },
  aeroflot: {
    href: "https://www.aeroflot.ru/ru-ru/afl_bonus",
    text: "Получайте мили «Аэрофлот Бонус» за оплату бронирований на сайте aeroflot.sutochno.ru"
  },
  afl: {
    href: "https://www.aeroflot.ru/ru-ru/afl_bonus",
    text: "Получайте 3 мили 'Аэрофлот Бонус' за каждые потраченные 100 ₽"
  }
};

const activeLink = computed(() => wlConfig[whitelabelName.value]);
</script>

<style lang="scss">
.get-miles {
  text-align: center;
}
.get-miles__text {
  padding: 20px;
  border-radius: 25px;
  background-color: #fff;
  color: #000;
  z-index: 1;
  position: relative;
  bottom: -100px;
  &:hover {
    color: #000;
  }

  @media only screen and (max-width: 576px) {
    display: block;
    padding: 10px;
    margin: 0 16px;
    bottom: -60px;
  }
}
</style>