<i18n lang="json">
{
  "en": {
    "whitelabelTitle": {
      "domclick": "Domclick together with Sutochno.ru",
      "levelTravel": "Level Travel together with Sutochno.ru"
    },
    "bookingGuarantee": "Let's find a place to stay!",
    "cityOrCountry": "Resort, city or address",
    "cityTitle": "Choose direction",
    "guest": "0|{n} guest|{n} guests|{n} guests",
    "idea": "Travel ideas",
    "moreThanVariants1": "Apartments, hotels and guest houses",
    "moreThanVariants2": "330 thousand options",
    "moreThanVariants3": "Apartments, hotels and guest houses",
    "occupiedIn": "Check-in",
    "occupiedOut": "Check-out",
    "startSearch": "Search",
    "payInfoTitle": "Payment information",
    "payInfoBody": "To view information about payments, go to your personal account under your phone number and use the login password sent by SMS",
    "where": "Where we go",
    "when": "When"
  },
  "ru": {
    "whitelabelTitle": {
      "domclick": "Домклик совместно с Суточно.ру",
      "levelTravel": "Level Travel совместно с Суточно.ру"
    },
    "bookingGuarantee": "Найдём, где остановиться!",
    "cityOrCountry": "Курорт, город или адрес",
    "cityTitle": "Выбрать направление",
    "guest": "0|{n} гость|{n} гостя|{n} гостей",
    "idea": "Идеи для поездок",
    "moreThanVariants1": "Квартиры, отели, гостевые дома",
    "moreThanVariants2": "330 тысяч вариантов",
    "moreThanVariants3": "Квартиры, отели и гостевые дома ",
    "occupiedIn": "Заезд",
    "occupiedOut": "Отъезд",
    "startSearch": "Найти",
    "payInfoTitle": "Информации об оплатах",
    "payInfoBody": "Для просмотра информации об оплатах, перейдите в личный кабинет под вашим номером телефона и используйте пароль для входа, высланный по СМС",
    "where": "Куда едем",
    "when": "Когда"
  }
}
</i18n>
<template>
  <div class="page-main" :class="{
    'partner-utair': isUtair,
    'partner-aeroflot': isAeroflot,
    'partner-afl': isAfl
  }">
    <Header />
    <div
      class="main-block"
      :class="{ 'main-block-whitelabel': whitelabel }"
      :style="whitelabelStyles"
    >
      <div id="modal-cookies"></div>
      <img
        ref="background"
        alt=""
        :src="whitelabelBackgroundSrc"
        class="lazy-background"
        :class="{ 'lazy-background-wl': whitelabel }"
      />
      <div class="main-block-whitelabel-title" v-if="isDomclick">
        {{ t("whitelabelTitle.domclick") }}
      </div>
      <div v-else-if=isLevelTravel class="main-block-whitelabel-title">
        {{ t("whitelabelTitle.levelTravel") }}
      </div>
      <h1>
        <transition name="fade">
          <span v-show="titlePage" class="main-block-title">{{
            titlePage
          }}</span>
        </transition>
        <span class="main-block-note"
          >{{ t("moreThanVariants1") }}<br />{{ t("moreThanVariants2") }}</span
        >
      </h1>
      <div class="main-block__panel-container">
        <SearchPanelMain class="main-block__panel"/>
      </div>
      <GetMiles />
    </div>
    <div class="block-s7" v-if="isS7" @click="onS7BannerCick">
      <div class="block-s7--logo">
        <img src="~/assets/img/s7-logo.svg" alt="">
      </div>
      <div class="block-s7--title">
        Получите 1 милю S7<br>
        Priority за 20 руб.
      </div>
      <div class="block-s7--hint">
        Введите ваш номер участника S7 Priority в процессе бронирования.
<!--        <p>Акция действует на бронирования, которые были созданы с 20 января 2025 года по 7 февраля 2025 года и период проживания в арендуемом помещении с 20 января 2025 года по 9 февраля 2025 года.</p>-->
<!--        <p>Введите ваш номер участника S7 Priority в процессе бронирования.</p>-->
<!--        <p>Мили S7 Priority будут начислены после завершения проживания.</p>-->
      </div>
      <div class="block-s7--link">
        <span>Условия программы</span>
      </div>
<!--      <a href="https://www.s7.ru/ru/offers/s7priority/fly-again/" target="_blank"></a>-->
      <a href="https://www.s7.ru/ru/s7priority/pravila-programmy/?from=s7-sutochno" target="_blank"></a>
    </div>

    <div v-if="!isVkApp && !whitelabel" class="main-footer">
      <!-- TODO временный костыль с инлайн оступами - нужен нормальный враппер -->
      <MobileGetCashback style="padding: 0px 16px" />
      <RouteDirection />
      <MobileWhereToGo style="padding: 0px 16px" />
      <MobileBookInApp style="margin-left: 16px; margin-right: 16px" />
      <!-- <CountReviewsAccommodation /> -->
      <MobilePopularAbroad style="padding: 0px 16px" />
    </div>

    <Footer v-if="!isVkApp && !isUtair && !isNotShowFooter" />
    <Modal
      v-if="isVkApp && $route.query.afterPayInfo"
      ref="modalPayInfo"
      :title="t('payInfoTitle')"
      theme="light"
    >
      <div class="modal-pay-info">
        {{ t("payInfoBody") }}
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import _ from "lodash";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import baseParams from "@/composables/gtag/partners/s7/baseParams";
import { Header } from "@sutochno/header";
import Modal from "@sutochno/modal";
import { Footer } from "@sutochno/footer";
import { Cian } from "@/utils/gtm/";
import RouteDirection from "@/components/mobile/Main/RouteDirection";
import CountReviewsAccommodation from "@/components/desktop/Main/CountReviewsAccommodation.vue";
import MobileGetCashback from "@/components/mobile/Main/MobileGetCashback.vue";
import MobileWhereToGo from "@/components/mobile/Main/MobileWhereToGo.vue";
import MobileBookInApp from "@/components/mobile/Main/MobileBookInApp.vue";
import MobilePopularAbroad from "@/components/mobile/Main/MobilePopularAbroad.vue";
import { useI18n } from 'vue-i18n';
import useMetaMain from '@/composables/meta/main';
import { event } from "vue-gtag";
import SearchPanelMain from "@/components/mobile/NewSearch/SearchParams/SearchPanelMain.vue";
import GetMiles from "@/components/base/Main/GetMiles.vue";

// import InstallingApplication from "@/components/base/InstallingApplication.vue";

const backgroundDefaultSrc = import.meta.glob("/assets/img/main/interior-little.jpg", { eager: true, query: "?url", import: "default" })?.["/assets/img/main/interior-little.jpg"];

export default {
  name: "Main",
  components: {
    MobilePopularAbroad,
    MobileBookInApp,
    MobileWhereToGo,
    MobileGetCashback,
    Header,
    Modal,
    Footer,
    CountReviewsAccommodation,
    RouteDirection,
    SearchPanelMain,
    GetMiles,
    // InstallingApplication,
  },
  setup() {
    const { t } = useI18n();

    useMetaMain();

    return {
      t
    }
  },
  data() {
    return {
      query: this.term,
      occupied: {},
      // location: {},
      // 0-2 Крым
      // 3-4 Сочи
      // 5 Ялта
      // 6 Питер
      backgroundIndex: Math.floor(Math.random() * 7),
      titlePage: this.t("bookingGuarantee"),
      isMainNotParams: false,
      isMainNoPath: false,
      mobileHeaderHeight: null,
      viewSearchWidget: false,
      activeField: "in",
    };
  },
  watch: {
    dateFromHistorylist(val, oVal) {
      if (val.in != oVal.in || val.out != oVal.out) {
        this.occupied = val;
        this.$store.dispatch("search/setCalendarDates", {
          checkIn: val.in,
          checkOut: val.out,
        });
      }
    },
  },
  computed: {
    ...mapGetters("search", [
      "maxGuests",
      "termEmpty",
      "getTypeOfSelectedVariant",
    ]),
    ...mapState("search", [
      "location",
      "term",
      "guests",
      "listSuggestions",
      "dateFromHistorylist",
      "checkIn",
      "checkOut",
    ]),
    ...mapState("user", ["isVkApp", "whitelabel"]),
    isCian() {
      return this.whitelabel && this.whitelabel.name === "cian";
    },
    isDomclick() {
      return this.whitelabel && this.whitelabel.name === "domclick";
    },
    isLevelTravel() {
      return this.whitelabel && this.whitelabel.name === "leveltravel";
    },
    isS7() {
      return this.whitelabel && this.whitelabel.name === "s7";
    },
    isUtair() {
      return this.whitelabel && this.whitelabel.name === "utair";
    },
    isAeroflot() {
      return this.whitelabel && this.whitelabel.name === "aeroflot";
    },
    isAfl() {
      return this.whitelabel?.name === "afl";
    },
    isNotShowFooter() {
      return this.whitelabel && (
        this.whitelabel.name === "utair"
        || this.whitelabel.name === "aeroflot"
        || this.whitelabel.name === "leveltravel"
        || this.whitelabel.name === "pobeda"
        || this.whitelabel.name === "licard"
        || this.whitelabel.name === "afl"
      );
    },
    whitelabelStyles() {
      if (!this.mobileHeaderHeight) {
        return null;
      }
      return `min-height: calc(100vh - ${this.mobileHeaderHeight}px)`;
    },
    whitelabelBackgroundSrc() {
      return this.whitelabel ? `https://storage.yandexcloud.net/whitelabel.sutochno.ru/${this.whitelabel.name}/images/bg-mobile.jpg` : backgroundDefaultSrc;
    }
  },
  beforeCreate() {
    this.$store.dispatch("search/reset");
  },
  beforeMount() {
    this.isMainNoPath = !!(
      window.location.pathname === "/" && !window.location.search
    );
    if (!this.isMainNoPath) {
      this.titlePage = "";
    }
  },
  async mounted() {
    this.setMobileHeaderHeight();
    //lsSet moved from beforeCreate for ssr
    localStorage.setItem("city", "{}");
    if (this.isCian) {
      Cian.gtmPartnerPageview({
        pageType: "Home"
      });
    }

    if(this.isS7) {
      event("load_bot", {
        ...baseParams,
        step: "main",
      })
    }

    this.$nextTick();

    if (!this.whitelabel && this.isMainNoPath && this.$i18n.locale === "ru") {
      this.isMainNotParams = true;
      this.titlePage = this.t("bookingGuarantee");
      // if (!this.$cookies.get("cookieFilesConfirmation")) {
      //   const modalCookies = document.createElement("script");
      //   modalCookies.setAttribute("data-cfasync", "false");
      //   modalCookies.setAttribute(
      //     "src",
      //     "/front/searchapp/disabled/modal-cookies.js"
      //   );
      //   document.body.appendChild(modalCookies);
      // }
    } else {
      this.titlePage = this.t("bookingGuarantee");
    }

    const getStorage = JSON.parse(
      localStorage.getItem("variantsHistory") || "[]"
    );
    if (getStorage.length) {
      const locationId = getStorage[0].location?.id;
      const storageLocation = JSON.parse(localStorage.getItem("location"));

      if (storageLocation && storageLocation.title && storageLocation.id !== 1 && storageLocation.id === locationId) {
        this.setTerm(storageLocation.title);
        this.setSelectedVariant(storageLocation);
      } else {
        this.setTerm(getStorage[0].title);
      }

      this.occupied.in = getStorage[0].date?.in ? new Date(getStorage[0].date?.in) : null;
      this.occupied.out = getStorage[0].date?.out ? new Date(getStorage[0].date?.out) : null;
      this.$store.dispatch("search/setCalendarDates", {
        checkIn: new Date(getStorage[0].date?.in),
        checkOut: new Date(getStorage[0].date?.out),
      });

      const guests = getStorage[0].guests;
      if (typeof (guests ?? undefined) === "object") {
      this.setGuests({
        adults: guests.adults || 2,
        childrens: guests.childrens || [],
        pets: {
          value: guests.pets?.value || false,
          description: guests.pets?.description || "",
        },
      });
    }
    }

    if (this.isVkApp && this.$route.query.afterPayInfo) {
      this.$refs.modalPayInfo.show();
    }
  },
  methods: {
    ...mapMutations("search", ["setTerm", "setGuests", "setSelectedVariant"]),
    setMobileHeaderHeight() {
      const mobileHeader = document.querySelector('.sc-header.mobile');
      if (mobileHeader) {
        this.mobileHeaderHeight = mobileHeader.offsetHeight;
      }
    },
    clickGuests() {
      sendEventGtag("dates_sa", {
        click: "main_guests",
      });
    },
    checkInOpen() {
      this.activeField = "in";
      this.viewSearchWidget = true;
    },
    gaCheckIn() {
      sendEventGtag("dates_sa", {
        click: "main_dates_in",
      });
    },
    checkOutOpen() {
      this.activeField = "out";
      this.viewSearchWidget = true;
    },
    gaCheckOut() {
      sendEventGtag("dates_sa", {
        click: "main_dates_out",
      });
    },
    format(date) {
      let date1 = date.toLocaleString("ru-RU", {
        day: "numeric",
        month: "short",
      });

      let date2 = date.toLocaleString("ru-RU", {
        weekday: "short",
      });
      return date1.slice(0, -1) + ", " + date2;
    },
    getFormatOccupied(date) {
      let format = (date) => {
        let month = (date.getMonth() + 1).toString().padStart(2, 0),
          day = date.getDate().toString().padStart(2, 0),
          year = date.getFullYear();
        return year + "-" + month + "-" + day;
      };
      return date.in && date.out
        ? format(date.in) + ";" + format(date.out)
        : null;
    },
    // под общее над переписать
    async WebpIsSupported() {
      // If the browser doesn't has the method createImageBitmap, you can't display webp format
      if (!self.createImageBitmap) return false;
      // Base64 representation of a white point image
      const webpData =
        "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=";
      // Retrieve the Image in Blob Format
      const blob = await fetch(webpData).then((r) => r.blob());
      // If the createImageBitmap method succeeds, return true, otherwise false
      return createImageBitmap(blob).then(
        () => true,
        () => false
      );
    },
    onS7BannerCick() {
      event("select_promotion", {
        ...baseParams,
        step: "banner",
        promotion_name: "click_banner",
        items: [
          {
            item_id: "banner_lottery",
            location_id: "sutochno_main"
          }
        ]
      })
    }
  },
};
</script>

<style lang="scss" scoped>
:deep(.sc-searchwidget__guest) {
  display: block !important;
}
.main-block {
  color: var(--color-main-black);
  padding: 60px 0px 40px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 600px;

  .partner-aeroflot &,
  .partner-utair & {
    height: calc(100vh - 52px);
    max-height: none;
  }

  .lazy-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 1;
    &-wl {
      opacity: 0.8;
    }
  }
  a {
    color: #ffffff;
    &:hover {
      color: #ffffff;
    }
  }
  &-whitelabel-title {
    font-size: 17px;
    line-height: 1;
    position: relative;
    z-index: 1;
    margin-bottom: 14px;
    text-align: center;
    padding-top: 30px;
  }
  &-title {
    max-width: 266px;
    margin-bottom: 10px;
    position: relative;
    margin-bottom: 7px;
    text-align: center;
    display: block;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
  }
  &-note {
    max-width: 266px;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    position: relative;
    text-align: center;
    display: block;
  }
  &-down {
    margin-top: 43px;
    display: block;
    white-space: nowrap;
    text-align: center;
    font-weight: normal;
  }

  &__panel-container {
    width: 100%;
  }
  &__panel {
    margin-top: 40px;
    padding: 0 16px;
  }
  &__quick-location-selection {
    padding: 16px 12px;
  }
}
.modal-pay-info {
  padding: 20px;
}
.main-footer {
  padding: 20px 0;
  h4 {
    text-indent: 10px;
  }

  .container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 767px) {
  .main-footer {
    .container {
      max-width: 100%;
    }
  }
}
.white-label.webview .page-main {
  .main-block {
    max-height: 100vh !important;
    min-height: 100vh !important;
  }
}
.block-s7 {
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
      "logo title"
      "hint hint"
      "link link";
  background: #FFFFFF;
  border-radius: 8px;
  padding: 20px;
  margin: 18px;
  box-shadow: 0 0 9px rgba(0, 0, 0, .1);
  color: #000;
  text-decoration: none;
  line-height: 1;
  position: relative;

  a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }

  &--logo {
    grid-area: logo;
    margin-right: 16px;

    img {
      width: 44px;
    }
  }

  &--title {
    grid-area: title;
    font-weight: bold;
    font-size: 19px;
    align-self: center;
  }

  &--hint {
    padding-top: 20px;
    grid-area: hint;
    margin-bottom: 18px;
    color: #524f4e;
    font-size: 14px;
    line-height: 1.4;
  }

  &--link {
    grid-area: link;
    font-size: 14px;

    span {
      color: #406d9e;
      text-decoration: underline;
    }
  }
}
.main-guests {
  margin-bottom: 14px;

  :deep(.select-guests--btn) {
    padding: 0 22px;
    display: flex;
    border-radius: 50px;
    height: 50px;

    .title {
      margin: 0;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: var(--color-gray-g5);
      opacity: 1;
    }
    .body {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--color-main-black);
    }
    .ico-arrow {
      display: none;
    }
  }
}
.main-block-whitelabel {
  background: #000;
  min-height: calc(100vh - 50px);
  max-height: unset;
  height: 100%;
  h1 {
    position: relative;
    z-index: 10;
    color: #ffffff;
  }
  &:after {
    background-color: #000;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    opacity: .3;
    position: absolute;
    right: 0;
    top: 0;
  }

  .main-block {
    &__panel-container {
      z-index: 11;
    }
  }
}
</style>
