<i18n lang="json">
{
  "ru": {
    "title": "Найдём, где остановиться!",
    "desc": "Квартиры, отели, гостевые дома — 330 тысяч вариантов для поездок по России и зарубежью"
  },
  "en": {
    "title": "Let's find a place to stay!",
    "desc": "Apartments, hotels, guest houses - 330 thousand options for trips in Russia and abroad"
  }
}
</i18n>

<template>
  <div class="title-block">
    <h1>{{ t("title") }}</h1>
    <p>{{ t('desc') }}</p>
  </div>
</template>
<script  setup>
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
</script>
<style lang="scss" scoped>
.title-block {
  margin-top: 110px;
  text-align: center;
  h1 {
    font-size: 52px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    color: #1C1C1C;
    margin-bottom: 0;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #5A5D63;
    margin-top: 21px;
  }
}
</style>
